/* Post Typography */

/*--------------
  Body
  -------------- */

body {
  color: $base-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

/*--------------
  Link
  -------------- */
a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}


/*--------------
  Header
  -------------- */

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.1;
  color: #333;
  letter-spacing: -.025rem;
}

h1 {
  font-size: 2.1rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.5rem;
}

h4{
  font-size: 1.3rem;
}

h5{
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
  color: #aaa;
}

/*--------------
  Align
  -------------- */

.center {
  text-align: center;

  &-image {
    margin: 0 auto;
    display: block;
  }
}

/*--------------
  Text
  -------------- */

p {
  margin: 0 0 1rem;
}

/*--------------
  Lists
  -------------- */

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  float: left;
  width: 180px;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  margin-bottom: .5rem;
}

dd {
  margin-left: 200px;
  margin-bottom: .5rem;
}

ul {
  list-style: inside circle;
  padding-left: 0;

  li {
    margin-bottom: .25rem;
  }

  ul,
  ol {
    list-style: inside;
    margin-left: 2rem;
    margin-top: .25rem;
    margin-bottom: .5rem;
  }
}

ol {
  list-style: inside decimal;
  padding-left: 0;

  li {
    margin-bottom: .25rem;
  }

  ul,
  ol {
    margin-left: 2rem;
    margin-top: .25rem;
    margin-bottom: .5rem;
  }
}

p + h1,
p + h2,
p + h3,
ul + h1,
ul + h2,
ul + h3,
ol + h1,
ol + h2,
ol + h3 {
  margin-top: 1.5rem;
}

hr {
  border: 0;
  border-top: .5px solid #eee;
  border-bottom: .5px solid #eee;
  margin: 1.5rem auto;
}

strong {
  color: #333;
  font-weight: 600;
}

abbr {
  font-size: 85%;
  font-weight: bold;
  color: #555;
  text-transform: uppercase;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #e5e5e5;
}

blockquote {
  padding: 0 0 0 1.5rem;
  margin: 0 2rem 1rem 0;
  color: #999;
  border-left: .5rem solid #e5e5e5;

  p:last-child {
    margin-bottom: 0;
  }
}

code,
pre {
  font-family: $code-font;
}

code {
  padding: .25em .5em;
  font-size: 85%;
  color: $code-color;
  background-color: $code-background-color;
  border-radius: 3px;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: .5rem;
  font-size: .8rem;
  line-height: 1.4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  // word-wrap: break-word;
  background-color: $code-background-color;
}

pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent;
}

.highlight {
  margin-bottom: 1rem;
  border-radius: 4px;
}

.highlight pre {
  margin-bottom: 0;
}

.gist .gist-file {
  font-family: $code-gist-font;
}

.gist .markdown-body {
  padding: 15px;
}

.gist pre {
  padding: 0;
  background-color: transparent;
}

.gist .gist-file .gist-data {
  font-size: .8rem !important;
  line-height: 1.4;
}

.gist code {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid $table-border-color;
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid $table-border-color;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: $table-background-color;
}
